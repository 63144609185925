
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { starRatings, hotelFeatureOptions, hotelFeatureOptionsExtended } from './helpers'

@Component
export default class HotelProfileModal extends Vue {
  @Prop()
  profile!: Record<string, any>
  @Prop({ type: Boolean })
  isEditing!: boolean

  // data // ***
  isSaving = false
  internalValue = {
    numberOfRooms: 0,
    starRating: 0,
    commissionRate: 0,
    hasAccessibleRooms: 0,
    hasFamilyRooms: 0,
    hasHotelBus: 0,
    hasParking: 0,
    hasSpa: 0,
    hasSwimmingPool: 0,
    hasGym: 0,
    hasRestaurant: 0,
  }

  // Computed
  get starRatings() {
    return starRatings
  }
  get hotelFeatureOptions() {
    return hotelFeatureOptions(this)
  }

  get hotelFeatureOptionsExtended() {
    return hotelFeatureOptionsExtended(this)
  }
  // watch // ***
  @Watch('isEditing')
  onValueChanged(e: boolean) {
    if (e) this.setInitialValues()
    else this.reset()
  }

  // mounted //
  setInitialValues() {
    this.internalValue.numberOfRooms = this.profile.numberOfRooms
    this.internalValue.starRating = this.profile.starRating
    this.internalValue.commissionRate = this.profile.commissionRate
    this.internalValue.hasAccessibleRooms = this.profile.hasAccessibleRooms
    this.internalValue.hasFamilyRooms = this.profile.hasFamilyRooms
    this.internalValue.hasHotelBus = this.profile.hasHotelBus
    this.internalValue.hasParking = this.profile.hasParking
    this.internalValue.hasSpa = this.profile.hasSpa
    this.internalValue.hasSwimmingPool = this.profile.hasSwimmingPool
    this.internalValue.hasGym = this.profile.hasGym
    this.internalValue.hasRestaurant = this.profile.hasRestaurant
  }

  // methods // ***
  async onSubmit() {
    const valid = await this.validateForm()
    if (!valid) return
    else this.$emit('submit', this.internalValue)
  }

  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }

  onCancel() {
    this.close()
  }
  close() {
    this.$emit('close', false)
  }
  reset() {
    this.internalValue = {
      numberOfRooms: 0,
      starRating: 0,
      commissionRate: 0,
      hasAccessibleRooms: 0,
      hasFamilyRooms: 0,
      hasHotelBus: 0,
      hasParking: 0,
      hasSpa: 0,
      hasSwimmingPool: 0,
      hasGym: 0,
      hasRestaurant: 0,
    }
  }
}
