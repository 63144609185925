
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CONTACT_ITEM_TYPES } from '@/utilities/constants'
import cloneDeep from 'lodash/cloneDeep'

const { ADDRESS } = CONTACT_ITEM_TYPES
const AUTOCOMPLETE = {}

@Component
export default class ContactItemModal extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  item!: Record<string, any> | undefined
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ required: true, type: [String, Number] })
  mode!: string | number
  @Prop({ required: true, type: [String, Number] })
  type!: string | number
  @Prop({ type: Boolean })
  value!: boolean

  // data // ***
  autocomplete: Record<string, any> = cloneDeep(AUTOCOMPLETE)
  showAutocomplete = true

  // computed // ***
  get isAddressType() {
    return this.type === ADDRESS
  }

  // created // ***
  created() {
    this.intialiseAutocomplete()
  }

  // watch // ***
  @Watch('value')
  onValueChanged() {
    this.intialiseAutocomplete()
  }

  // methods // ***
  onInput(e: Record<string, any>): void {
    const payload = { item: this.item || {}, values: e?.data || {} }
    this.$emit('input:autocomplete', payload)
  }
  onFieldInput(e: Record<string, any>): void {
    const { item, mode, type } = this
    this.$emit('input:field', { ...e, item, mode, type })
  }
  intialiseAutocomplete(): void {
    this.autocomplete = cloneDeep(AUTOCOMPLETE)
    this.showAutocomplete = false
    this.$nextTick(() => {
      this.showAutocomplete = true
    })
  }
}
