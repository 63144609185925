export const starRatings = [
  { text: 'Unknown', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
]

export const hotelFeatureOptions = (context: Record<string, any>): Record<string, any>[] => {
  return [
    { text: String(context.$t('cmac.hotelRates.profileUnkown')), value: 0 },
    { text: String(context.$t('cmac.hotelRates.profileYes')), value: 1 },
    { text: String(context.$t('cmac.hotelRates.profileNo')), value: 2 },
  ]
}

export const hotelFeatureOptionsExtended = (
  context: Record<string, any>
): Record<string, any>[] => {
  return [
    { text: String(context.$t('cmac.hotelRates.profileUnkown')), value: 0 },
    { text: String(context.$t('cmac.hotelRates.profileYes')), value: 1 },
    { text: String(context.$t('cmac.hotelRates.profileNo')), value: 2 },
    { text: String(context.$t('cmac.hotelRates.profileYesFree')), value: 3 },
  ]
}
