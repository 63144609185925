
import api from '@/utilities/api'
import { Component, Vue, Watch } from 'vue-property-decorator'
import HotelProfileModal from './HotelProfileModal'
import { hotelFeatureOptionsExtended, starRatings } from './helpers'

@Component({
  components: {
    'hotel-profile-modal': HotelProfileModal,
  },
})
export default class HotelProfileDetails extends Vue {
  isEditing = false
  profile = {
    numberOfRooms: 0,
    starRating: 0,
    hasAccessibleRooms: 0,
    hasFamilyRooms: 0,
    hasHotelBus: 0,
    hasParking: 0,
    hasSpa: 0,
    hasSwimmingPool: 0,
    hasGym: 0,
    hasRestaurant: 0,
  }
  loading = false
  // computed // ***
  get details(): Record<string, any> {
    return {
      name: this.supplier.name,
      website: { href: 'https://cmacgroup.com', text: 'cmacgroup.com' },
    }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get starRatings() {
    return starRatings
  }
  get hotelFeatureOptions() {
    return hotelFeatureOptionsExtended(this)
  }

  // created // ***
  created() {
    this.getProfile()
  }

  @Watch('supplier')
  onSupplierChanged(): void {
    this.getProfile()
  }

  // methods // ***
  async getProfile() {
    if (!this.supplier?.id) return
    this.loading = true
    const companyProfile =
      (await api(
        'organisation/SupplierCompanyProfile/' + this.supplier?.id,
        undefined,
        undefined
      )) || {}
    if (!companyProfile) {
      this.loading = false
      return
    }
    var hotelData = companyProfile?.data?.hotelAttributes
    if (!hotelData) {
      this.loading = false
      return
    }
    this.profile = hotelData
    this.loading = false
  }
  getHotelFeatureOptionText(value: number) {
    return this.hotelFeatureOptions.find(x => x.value === value)?.text || 'Unknown'
  }
  onEdit() {
    this.isEditing = true
  }
  onClose() {
    this.isEditing = false
  }
  onSubmit(payload: Record<string, any>) {
    let data = {
      hotelAttributes: payload,
    }
    api(
      'organisation/SupplierHotelProfile/' + this.supplier?.id,
      { data, method: 'PUT', json: true },
      undefined
    ).then(() => {
      this.isEditing = false
      this.getProfile()
    })
  }
}
