
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CONTACT_ITEM_TYPES } from '@/utilities/constants'
import { getAddress } from '@/pages/profile/contactItems/helpers'

const { ADDRESS, EMAIL_ADDRESS, TELEPHONE_NUMBER } = CONTACT_ITEM_TYPES

@Component
export default class ContactItemDeleteModal extends Vue {
  // props // ***
  @Prop({ default: '600px' })
  maxWidth!: string | number
  @Prop({ type: Object })
  item!: Record<string, any>
  @Prop()
  loading!: boolean
  @Prop()
  type!: string | number
  @Prop()
  value!: boolean

  // computed // ***
  get itemValue() {
    const { item, type } = this
    if (type === ADDRESS) return getAddress(item)
    else return item.value
  }
  get title() {
    return `Delete ${this.typeName}`
  }
  get typeName() {
    switch (this.type) {
      case ADDRESS:
        return this.$t('fields.address')
      case EMAIL_ADDRESS:
        return this.$t('fields.emailAddress')
      case TELEPHONE_NUMBER:
        return this.$t('fields.phoneNumber')
      default:
        return 'Unknown'
    }
  }

  // methods // ***
  onCancel() {
    this.$emit('input', false)
  }
  onInput(e: boolean) {
    this.$emit('input', e)
  }
  onSubmit() {
    this.$emit('submit')
  }
}
