
import api from '@/utilities/api'
import { isRequired, isUkBankAccountNumber, isUkBankSortCode } from '@/utilities/validations'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component
export default class CompanyDetails extends Vue {
  // props // ***
  @Prop({ type: Boolean, default: false })
  isDisabled!: boolean

  // data // ***
  companyProfile: Record<string, any> = {}
  addresses: Record<string, any>[] = []
  editing = false
  currencies: Record<string, any>[] = [
    { name: 'GBP', id: 'GBP' },
    { name: 'EUR', id: 'EUR' },
  ]

  // computed // ***
  get fetchingCompanyProfile(): boolean {
    const { getCompanyProfile: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get updatingCompanyProfile(): boolean {
    const { updateCompanyProfile: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'PUT', url })
  }
  get isUK(): boolean {
    return this.addresses && this.addresses.length > 0 && this.addresses[0].isoCountryCode === 'GB'
  }

  get fields(): Record<string, any>[] {
    let dataFields = [
      {
        name: 'accountName',
        label: this.$t('cmac.financialDetails.accountName'),
        rules: [isRequired],
      },
      {
        name: 'bankAddress',
        label: this.$t('cmac.financialDetails.bankAddress'),
        rules: [isRequired],
      },
    ]

    if (this.isUK) {
      dataFields.push({
        name: 'sortCode',
        label: 'Sort Code',
        rules: [isUkBankSortCode, isRequired],
      })
      dataFields.push({
        name: 'bankAccountNumber',
        label: 'Bank Account Number',
        rules: [isUkBankAccountNumber, isRequired],
      })
    } else {
      dataFields.push({ name: 'iban', label: 'IBAN', rules: [isRequired] })
      dataFields.push({ name: 'swift', label: 'Swift Code', rules: [isRequired] })
    }

    const currencyItem = {
      name: 'currency',
      items: this.currencies.map((t: Record<string, any>) => ({
        text: t.name,
        value: t.id,
      })),
      label: this.$t('cmac.financialDetails.currency'),
      rules: [isRequired],
      component: 'v-select',
    }

    dataFields.push(currencyItem)

    return dataFields
  }
  get item(): Record<string, any> {
    const { swift, sortCode, iban, bankAddress, bankAccountNumber, accountName, currency } =
      this.companyProfile
    return { swift, sortCode, iban, bankAddress, bankAccountNumber, accountName, currency }
  }
  get loading(): boolean {
    const { fetchingCompanyProfile } = this
    return fetchingCompanyProfile
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get urls() {
    return {
      getAddresses: 'organisation/getAddresses/' + this.supplier?.id,
      getCompanyProfile: 'organisation/SupplierCompanyProfile/' + this.supplier?.id,
      updateCompanyProfile: 'organisation/SupplierFinancialDetails/' + this.supplier?.id,
    }
  }

  get fetchingAddresses(): boolean {
    const { getAddresses: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }

  // created // ***
  created() {
    this.onCreated()
  }

  @Watch('supplier')
  onSupplierChanged(): void {
    this.fetch()
    this.fetchAddresses()
  }

  // methods // ***
  async fetchAddresses() {
    if (!this.supplier?.id) return
    const { getAddresses } = this.urls
    const items = (await api(getAddresses, undefined, undefined)) || {}
    if (!items?.data) return
    this.addresses = items?.data
  }
  async fetch() {
    if (!this.supplier?.id) return
    const { getCompanyProfile } = this.urls
    const companyProfile = (await api(getCompanyProfile, undefined, undefined)) || {}
    if (!companyProfile?.data) return
    this.companyProfile = companyProfile?.data
  }
  async onCreated() {
    this.fetch()
    this.fetchAddresses()
  }
  onEdit() {
    this.editing = true
  }
  onSubmit(values: Record<string, any>) {
    const data = { ...this.companyProfile, ...values }
    const { updateCompanyProfile: url } = this.urls
    const payload = { data, method: 'PUT', json: true }
    api(url, payload, undefined).then(() => {
      this.editing = false
      this.$emit('updated', values)
      this.fetch()
    })
  }
}
