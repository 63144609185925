
import api from '@/utilities/api'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  // data // ***
  companyProfile: Record<string, any> = {}
  companyOnBoardingProfile: Record<string, any> = {}
  progress = 0.5
  values: Record<string, any> = { documentTypes: [] }
  addresses: Record<string, any>[] = []

  get currentPercentText(): string {
    return `${Math.ceil(this.currentPercent)}%`
  }

  get currentPercent(): number {
    return (this.currentProgress / this.totalProgress) * 100
  }

  get isCompleted(): boolean {
    return this.totalProgress === this.currentProgress
  }

  get isUK(): boolean {
    return this.addresses && this.addresses.length > 0 && this.addresses[0].isoCountryCode === 'GB'
  }

  get isSmallVehicleProfile(): boolean {
    return this.companyOnBoardingProfile.isSmallVehicle
  }

  get isLargeVehicleProfile(): boolean {
    return this.companyOnBoardingProfile.isLargeVehicle
  }

  get isRejected(): boolean {
    return this.companyOnBoardingProfile.status == 5
  }

  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }

  get organisationId(): string {
    return String(this.supplier?.id)
  }

  get params() {
    const { organisationId, values } = this
    const { documentTypes: documentTypeIds } = values
    return { documentTypeIds, organisationId, ReturnAllResults: true }
  }

  get urls() {
    return {
      getAddresses: 'organisation/getAddresses/' + this.supplier?.id,
      getCompanyProfile: 'organisation/SupplierCompanyProfile/' + this.supplier?.id,
      fetchDocuments: 'OrganisationDocument/SearchDocumentsForSupplier',
      getOnBoardingProfile: 'supplieronboarding/getSupplierOnBoarding',
    }
  }

  get totalProgress(): number {
    //fields + documents
    if (this.isUK) {
      return 5 + 4 + (this.isLargeVehicleProfile ? 1 : 0) + (this.isSmallVehicleProfile ? 1 : 0)
    }

    return 5 + 1 + (this.isLargeVehicleProfile ? 1 : 0) + (this.isSmallVehicleProfile ? 1 : 0)
  }

  get currentProgress(): number {
    let progress = 0
    progress += this.companyProfile.currency && this.companyProfile.currency.length > 0 ? 1 : 0
    progress +=
      this.companyProfile.accountName && this.companyProfile.accountName.length > 0 ? 1 : 0
    progress +=
      this.companyProfile.bankAddress && this.companyProfile.bankAddress.length > 0 ? 1 : 0
    if (this.isUK) {
      progress +=
        this.companyProfile.bankAccountNumber && this.companyProfile.bankAccountNumber.length > 0
          ? 1
          : 0
      progress += this.companyProfile.sortCode && this.companyProfile.sortCode.length > 0 ? 1 : 0
    } else {
      progress += this.companyProfile.iban && this.companyProfile.iban.length > 0 ? 1 : 0
      progress += this.companyProfile.swift && this.companyProfile.swift.length > 0 ? 1 : 0
    }
    progress = progress + this.documents.length
    return progress
  }

  get documents() {
    return this.$store.getters['suppliers/documents']
  }

  // created // ***
  created() {
    this.refresh()
  }

  // methods // ***
  async fetch() {
    const { params: data, urls } = this
    const { fetchDocuments: url } = urls
    const options = { data, json: true, method: 'POST', url }
    await this.$store.dispatch('suppliers/fetchDocuments', options)
    const { getCompanyProfile, getOnBoardingProfile } = this.urls
    const companyProfile = (await api(getCompanyProfile, undefined, undefined)) || {}
    if (!companyProfile?.data) return
    this.companyProfile = companyProfile?.data

    const onBoardingProfile = (await api(getOnBoardingProfile, undefined, undefined)) || {}
    if (!onBoardingProfile?.data) return
    this.companyOnBoardingProfile = onBoardingProfile?.data

    const { getAddresses } = this.urls
    // fetch contact items
    const items = (await api(getAddresses, undefined, undefined)) || {}
    if (!items?.data) return
    this.addresses = items?.data
  }
  public refresh() {
    this.fetch()
  }
}
