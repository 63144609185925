
import api from '@/utilities/api'
import { title } from '@/utilities/filters'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CompanyDetails extends Vue {
  // props // ***
  @Prop({ type: Boolean, default: false })
  isDisabled!: boolean

  // data // ***
  companyProfile: Record<string, any> = {}
  editing = false
  vatTreatments: Record<string, any>[] = []

  // computed // ***
  get fetchingCompanyProfile(): boolean {
    const { getCompanyProfile: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get updatingCompanyProfile(): boolean {
    const { updateCompanyProfile: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'PUT', url })
  }
  get fetchingVatTreatments(): boolean {
    const { getVatTreatments: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get fields(): Record<string, any>[] {
    return [
      { name: 'companyNumber', label: title(this.$t('cmac.companyNumber') as string) },
      { name: 'vatNumber', label: this.$t('cmac.vatNumber') },
      {
        name: 'vatTreatmentId',
        items: this.vatTreatments.map((t: Record<string, any>) => ({
          text: t.name,
          value: t.id,
        })),
        label: this.$t('cmac.vatTreatment'),
        component: 'v-select',
      },
      { name: 'webSiteUrl', label: this.$t('common.website') },
    ]
  }
  get item(): Record<string, any> {
    const { companyNumber, vatNumber, vatTreatmentId, webSiteUrl } = this.companyProfile
    return { companyNumber, vatNumber, vatTreatmentId, webSiteUrl }
  }
  get loading(): boolean {
    const { fetchingCompanyProfile, fetchingVatTreatments } = this
    return fetchingCompanyProfile || fetchingVatTreatments
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get urls() {
    return {
      getCompanyProfile: 'organisation/SupplierCompanyProfile/' + this.supplier?.id,
      updateCompanyProfile: 'organisation/SupplierCompanyProfile/' + this.supplier?.id,
      getVatTreatments: 'organisation/getVatTreatments',
    }
  }
  get vatTreatment(): string {
    const id = this.companyProfile?.vatTreatmentId
    const treatment = this.vatTreatments.find((t: Record<string, any>) => t.id === id)
    const formattedName = title(treatment?.name || '')
    return formattedName
  }
  get website() {
    return this.companyProfile?.webSiteUrl
  }

  // created // ***
  created() {
    this.onCreated()
  }

  @Watch('supplier')
  onSupplierChanged(): void {
    this.fetch()
  }

  // methods // ***
  async fetch() {
    if (!this.supplier?.id) return
    const { getCompanyProfile, getVatTreatments } = this.urls
    // fetch vat treatment types
    const vatTreatments = (await api(getVatTreatments, undefined, undefined)) || {}
    if (!vatTreatments?.data) return
    this.vatTreatments = vatTreatments?.data
    // fetch company profile types
    const companyProfile = (await api(getCompanyProfile, undefined, undefined)) || {}
    if (!companyProfile?.data) return
    this.companyProfile = companyProfile?.data
  }
  async onCreated() {
    this.fetch()
  }
  onEdit() {
    this.editing = true
  }
  onSubmit(values: Record<string, any>) {
    const data = { ...this.companyProfile, ...values }
    const { updateCompanyProfile: url } = this.urls
    const payload = { data, method: 'PUT', json: true }
    api(url, payload, undefined).then(() => {
      this.editing = false
      this.fetch()
    })
  }
}
