
import api from '@/utilities/api'
import { Component, Vue, Watch } from 'vue-property-decorator'
import CompanyDetails from './CompanyDetails'
import FinancialDetails from './FinancialDetials.vue'
import OnBoardingProfileProgress from './OnBoardingProfileProgress.vue'
import OnBoardingDocuments from './RequiredDocuments.vue'
import SuperAdminFunctions from './SuperAdminFunctions'
import UserProfileDetails from './UserProfileDetails'
import ContactItems from './contactItems/ContactItems'
import HotelProfile from './hotelProfile/HotelProfile'
@Component({
  components: {
    'company-details': CompanyDetails,
    'contact-items': ContactItems,
    'super-admin-functions': SuperAdminFunctions,
    'user-profile-details': UserProfileDetails,
    'hotel-profile': HotelProfile,
    'financial-details': FinancialDetails,
    'on-boarding-documents': OnBoardingDocuments,
    'on-boarding-progress': OnBoardingProfileProgress,
  },
})
export default class Profile extends Vue {
  // computed // ***

  get activeUser() {
    return this.$auth.activeUser
  }
  get authApiUserIsSuperAdmin(): boolean {
    return this.$auth.authApiUserIsSuperAdmin
  }
  get hasHotelRatesFeature(): boolean {
    if (this.$store.getters['core/featureDisabled']('hotelRates')) {
      return false
    }
    return true
  }
  get userIsOnBoarding(): boolean {
    return this.$auth.isOnBoardingUser
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get urls() {
    return {
      getCompanyProfile: 'organisation/SupplierCompanyProfile/' + this.supplier?.id,
      getOnBoardingProfile: 'supplieronboarding/getSupplierOnBoarding',
    }
  }
  get isPermitHolder(): boolean {
    return this.activeUser.roles
      ? this.activeUser.roles.findIndex((x: any) => x.id == 2) > -1
      : false
  }
  get isHotelSupplier(): boolean {
    return this.activeUser.roles
      ? this.activeUser.roles.findIndex((x: any) => x.id == 9) > -1
      : false
  }
  get isRejected(): boolean {
    return this.companyOnBoardingProfile.status == 5
  }
  $refs!: {
    progressBar: OnBoardingProfileProgress
  }

  onBoardingProgress = 0
  onBoardingTotalProgress = 100
  companyOnBoardingProfile: Record<string, any> = {}

  // watch // ***
  @Watch('features')
  onFeaturesChanged(): void {
    this.redirectIfDisabled()
  }

  // created // ***
  created() {
    this.redirectIfDisabled()
    this.fetch()
  }

  onProgressRefresh() {
    this.$refs.progressBar.refresh()
  }

  // methods // ***
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('profile')) this.$router.push('/errors/404')
  }

  async fetch() {
    const { getOnBoardingProfile } = this.urls
    const onBoardingProfile = (await api(getOnBoardingProfile, undefined, undefined)) || {}
    if (!onBoardingProfile?.data) return
    this.companyOnBoardingProfile = onBoardingProfile?.data
  }

  updateOnBoardingProgress(e: { total: number; completed: number }) {
    this.onBoardingProgress = e.completed
    this.onBoardingTotalProgress = e.total
  }
}
