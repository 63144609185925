
import { Component, Vue } from 'vue-property-decorator'
import { endImpersonation, onImpersonatedUserSelected } from '@/utilities/org'

@Component
export default class SuperAdminFunctions extends Vue {
  // data // ***
  showImpersonationSelectionModal = false
  showOtherOrganisations = false

  // computed // ***
  get activeUser(): Record<string, any> {
    return this.$auth.activeUser
  }
  get impersonatedUserName(): null | Record<string, any> {
    return this.$auth.impersonatedUser?.id || ''
  }
  get impersonating(): boolean {
    return this.$auth.impersonating
  }
  get impersonatableUsers() {
    return this.$store.getters['core/impersonatableUsers']
  }
  get activeUserOrganisations() {
    const orgs = this.activeUser.organisations || []
    const orgNames = orgs.map((o: Record<string, any>) => o.name)
    return orgNames.join(', ') + '.'
  }
  get activeUserRoles() {
    const roles = this.activeUser.roles || []
    const roleNames = roles.map((r: Record<string, any>) => r.name)
    return roleNames.join(', ') + '.'
  }

  // methods // ***
  endImpersonation() {
    endImpersonation(this)
  }
  onUserSelected(e: Record<string, any> = {}) {
    onImpersonatedUserSelected(this, e)
  }
}
