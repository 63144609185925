export const getAddress = (item: Record<string, any>): string => {
  const { address1, address2, town, region, country, postCode } = item
  const ordered = [address1, address2, town, region, country, postCode]
  return ordered.reduce((a, v, i) => {
    if (!v?.length) return a
    const isLast = i === ordered.length - 1
    const punctuation = isLast ? '.' : ', '
    return a + v + punctuation
  }, '')
}
