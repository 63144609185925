
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class UserProfileDetails extends Vue {
  // computed // ***
  get details(): Record<string, any> {
    return {
      name: this.supplier.name,
      website: { href: 'https://cmacgroup.com', text: 'cmacgroup.com' },
    }
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
}
