
import UploadDocumentModal from '@/pages/documents/UploadDocumentModal.vue'
import api from '@/utilities/api'
import { downloadAjax } from '@/utilities/functions'
import { DateTime } from 'luxon'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component({
  components: {
    'c-upload-document-modal': UploadDocumentModal,
  },
})
export default class CompanyDetails extends Vue {
  // props // ***
  @Prop({ type: Boolean, default: false })
  isDisabled!: boolean
  // data // ***
  companyProfile: Record<string, any> = {}
  addresses: Record<string, any>[] = []
  addressTypes: Record<string, any>[] = []
  companyOnBoardingProfile: Record<string, any> = {}
  values: Record<string, any> = { documentTypes: [] }
  editing = false
  vatTreatments: Record<string, any>[] = []
  uploadModalOpen = false

  // data // ***
  $refs!: {
    docuemntUploadDlg: UploadDocumentModal
  }

  // computed // ***
  get fetchingCompanyProfile(): boolean {
    const { getCompanyProfile: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get fetchingSupplierOnBoardingProfile(): boolean {
    const { getOnBoardingProfile: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get documents() {
    return this.$store.getters['suppliers/documents']
  }
  get isUK(): boolean {
    return this.addresses.filter((x: Record<string, any>) => x.isoCountryCode === 'GB').length > 0
  }
  get isSmallVehicleProfile(): boolean {
    return this.companyOnBoardingProfile.isSmallVehicle
  }

  get isLargeVehicleProfile(): boolean {
    return this.companyOnBoardingProfile.isLargeVehicle
  }

  get organisationId(): string {
    return String(this.supplier?.id)
  }
  get params() {
    const { organisationId, values } = this
    const { documentTypes: documentTypeIds } = values
    return { documentTypeIds, organisationId, ReturnAllResults: true }
  }

  get fields(): Record<string, any>[] {
    let localFields = []
    localFields.push({
      description: this.$t('onboarding.documents.public_insurance'),
      id: 67,
      file: '',
      expires: '',
      uploadedOn: '',
      documentId: null,
      requiresExpiryDate: true,
    })
    if (this.isUK) {
      localFields.push({
        description: this.$t('onboarding.documents.liability'),
        id: 66,
        file: '',
        expires: '',
        uploadedOn: '',
        documentId: null,
        requiresExpiryDate: true,
      })
      localFields.push({
        description: this.$t('onboarding.documents.motor_insurance'),
        id: 2,
        file: '',
        expires: '',
        uploadedOn: '',
        documentId: null,
        requiresExpiryDate: true,
      })
      localFields.push({
        description: this.$t('onboarding.documents.gdpr'),
        id: 80,
        file: '',
        expires: '',
        uploadedOn: '',
        documentId: null,
        requiresExpiryDate: false,
      })
    }

    if (this.isSmallVehicleProfile) {
      localFields.push({
        description: this.$t('onboarding.documents.small_operator_license'),
        id: 19,
        file: '',
        expires: '',
        uploadedOn: '',
        documentId: null,
        requiresExpiryDate: true,
      })
    }

    if (this.isLargeVehicleProfile) {
      localFields.push({
        description: this.$t('onboarding.documents.large_operator_license'),
        id: 81,
        file: '',
        expires: '',
        uploadedOn: '',
        documentId: null,
        requiresExpiryDate: true,
      })
    }

    let existingDocumentsCounter = 0
    for (const record of localFields) {
      const existingDocument = this.documents.find((x: any) => x.documentType.id == record.id)
      if (existingDocument) {
        record.file = existingDocument.filename
        record.expires = existingDocument.expiryDate
        record.uploadedOn = existingDocument.createdDateUtc
        record.documentId = existingDocument.organisationDocumentId
        existingDocumentsCounter++
      }
    }

    this.$emit('onDocumentProgress', {
      total: localFields.length,
      completed: existingDocumentsCounter,
    })

    return localFields
  }
  get loading(): boolean {
    const { fetchingCompanyProfile, fetchingSupplierOnBoardingProfile } = this
    return fetchingCompanyProfile && fetchingSupplierOnBoardingProfile
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get urls() {
    return {
      getAddresses: 'organisation/getAddresses/' + this.supplier?.id,
      getAddressTypes: 'organisation/getAddressTypes',
      getCompanyProfile: 'organisation/SupplierCompanyProfile/' + this.supplier?.id,
      fetch: 'OrganisationDocument/SearchDocumentsForSupplier',
      delete: 'OrganisationDocument/RequestDelete',
      getOnBoardingProfile: 'supplieronboarding/getSupplierOnBoarding',
    }
  }
  get fetchingDocuments(): boolean {
    const { fetch: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }

  // created // ***
  created() {
    this.onCreated()
  }

  @Watch('supplier')
  onSupplierChanged(): void {
    this.fetch()
  }

  // methods // ***
  getDownloadUrl(i: Record<string, any>): string {
    return `OrganisationDocument/open/${i?.documentId}`
  }
  async fetchAddresses() {
    if (!this.supplier?.id) return
    const { getAddresses, getAddressTypes } = this.urls
    // fetch address types
    const types = (await api(getAddressTypes, undefined, undefined)) || {}
    if (!types?.data) return
    this.addressTypes = types?.data
    // fetch contact items
    const items = (await api(getAddresses, undefined, undefined)) || {}
    if (!items?.data) return
    this.addresses = items?.data
  }
  async onDownload(i: Record<string, any>) {
    await downloadAjax(this.getDownloadUrl(i), i?.file)
  }
  onUploadDocument(i: Record<string, any>) {
    this.$refs.docuemntUploadDlg.onTypeSelected(i)
    this.uploadModalOpen = true
  }

  async fetchAll() {
    await this.fetch()
    await this.fetchAddresses()
  }

  async fetch() {
    if (!this.supplier?.id) return
    const { params: data, urls } = this
    const { fetch: url } = urls
    const options = { data, json: true, method: 'POST', url }
    await this.$store.dispatch('suppliers/fetchDocuments', options)
    const { getCompanyProfile, getOnBoardingProfile } = this.urls
    const companyProfile = (await api(getCompanyProfile, undefined, undefined)) || {}

    if (!companyProfile?.data) return
    this.companyProfile = companyProfile?.data
    const onBoardingProfile = (await api(getOnBoardingProfile, undefined, undefined)) || {}
    if (!onBoardingProfile?.data) return
    this.companyOnBoardingProfile = onBoardingProfile?.data
  }
  async onCreated() {
    this.fetchAll()
  }

  onUpload() {
    this.$emit('updated')
    this.fetchAll()
  }
  formatDate(date: string): string {
    if (!date) return ''
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
  }
}
