
import api from '@/utilities/api'
import { CONTACT_ITEM_MODAL_MODES, CONTACT_ITEM_TYPES } from '@/utilities/constants'
import {
  isAlphaNumeric,
  isAlphaNumericWithSpecials,
  isEmailAddress,
  isRequired,
  isTelephoneNumber,
  minChar,
} from '@/utilities/validations'
import cloneDeep from 'lodash/cloneDeep'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ContactItemAddress from './ContactItemAddress'
import ContactItemDeleteModal from './ContactItemDeleteModal'
import ContactItemEmailAddress from './ContactItemEmailAddress'
import ContactItemModal from './ContactItemModal'
import ContactItemTelephoneNumber from './ContactItemTelephoneNumber'

const { ADD, EDIT } = CONTACT_ITEM_MODAL_MODES
const { ADDRESS, EMAIL_ADDRESS, TELEPHONE_NUMBER, EMPTY } = CONTACT_ITEM_TYPES
const DEFAULT_DELETE_MODAL_DATA = { item: {}, mode: ADD, open: false, type: EMPTY }
const DEFAULT_MODAL_DATA = { item: {}, mode: ADD, open: false, type: EMPTY }

@Component({
  components: {
    'contact-item-address': ContactItemAddress,
    'contact-item-email-address': ContactItemEmailAddress,
    'contact-item-delete-modal': ContactItemDeleteModal,
    'contact-item-modal': ContactItemModal,
    'contact-item-telephone-number': ContactItemTelephoneNumber,
  },
})
export default class ContactItems extends Vue {
  // props // ***
  @Prop({ type: Boolean, default: false })
  isDisabled!: boolean

  @Prop({ type: Boolean, default: false })
  isOnBoarding!: boolean
  @Prop({ type: Boolean, default: false })
  isPermitHolder!: boolean
  @Prop({ type: Boolean, default: false })
  isHotelSupplier!: boolean
  // data // ***
  addresses: Record<string, any>[] = []
  addressTypes: Record<string, any>[] = []
  contactItems: Record<string, any>[] = []
  contactItemTypes: Record<string, any>[] = []
  deleteModal: Record<string, any> = cloneDeep(DEFAULT_DELETE_MODAL_DATA)
  modal: Record<string, any> = cloneDeep(DEFAULT_MODAL_DATA)

  showEmailAddressModal = false
  savingEmailAddress = false
  emailAddressToEdit: any = null
  emailContactItemTypes: any = [
    { text: 'contactItem.generalManager', value: 'General Manager', type: 2 },
    { text: 'contactItem.reception24Hour', value: 'Reception', type: 2 },
    { text: 'contactItem.revenueSales', value: 'Revenue/Sales', type: 2 },
    { text: 'contactItem.twentyFourHourEmail', value: '24 Hour Email', type: 2 },
    { text: 'contactItem.notification', value: 'Notification', type: 9 },
    { text: 'contactItem.other', value: 'Other', type: 2 },
  ]

  // computed // ***
  canDelete() {
    return !this.isOnBoarding && this.addresses.length > 2
  }
  canEdit() {
    return !this.isOnBoarding
  }
  get requiredRule() {
    return isRequired
  }
  get isEmailRule() {
    return isEmailAddress
  }
  get emailAddressModalTitle() {
    return this.emailAddressToEdit?.id > 0 ? 'cmac.editEmailAddress' : 'cmac.addEmailAddress'
  }
  get computedEmailContactItemTypes() {
    return this.emailContactItemTypes.map((emailContactItemType: Record<string, any>) => {
      return {
        ...emailContactItemType,
        text: this.$t(emailContactItemType.text),
        value: this.$t(emailContactItemType.text),
      }
    })
  }
  get addressesWithTypes() {
    return this.addresses.map((a: Record<string, any>) => {
      const types = a.addressType
      return {
        ...a,
        isBillingAddress: types.includes('BillingAddress'),
        isDepotAddress: types.includes('CoverageArea'),
        autocomplete: a,
      }
    })
  }
  get CONTACT_ITEM_TYPES() {
    return cloneDeep(CONTACT_ITEM_TYPES)
  }
  get deleteModalLoading(): boolean {
    const { deletingAddress, deletingContactItem } = this
    return deletingAddress || deletingContactItem
  }
  get deletingAddress(): boolean {
    const { deleteAddress: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get deletingContactItem(): boolean {
    const { deleteContactItem: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get emailAddresses(): Record<string, any>[] {
    return this.getContactItemsByType('Email')
  }
  get hasUpdateNotificationEmail() {
    return this.emailAddresses.some(x => x.contactItemType === 9)
  }
  get fetchingAddresses(): boolean {
    const { getAddresses: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get fetchingAddressTypes(): boolean {
    const { getAddressTypes: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get fetchingContactItems(): boolean {
    const { getContactItems: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get fetchingContactItemTypes(): boolean {
    const { getContactItemTypes: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
  }
  get fields(): Record<string, any>[] {
    const { type } = this.modal
    const fields = {
      address: [
        {
          name: 'description',
          label: this.$t('common.description'),
          rules: [isAlphaNumericWithSpecials, isRequired],
        },
        {
          name: 'address1',
          label: this.$t('fields.addressLine1'),
          rules: [isAlphaNumericWithSpecials, isRequired],
        },
        {
          name: 'address2',
          label: this.$t('fields.addressLine2'),
          rules: [isAlphaNumericWithSpecials],
        },
        { name: 'town', label: this.$t('fields.town'), rules: [isAlphaNumericWithSpecials] },
        { name: 'region', label: this.$t('fields.region'), rules: [isAlphaNumericWithSpecials] },
        { name: 'country', label: this.$t('fields.country'), rules: [isAlphaNumeric] },
        { name: 'postCode', label: this.$t('fields.postCode'), rules: [isAlphaNumeric] },
        {
          boolean: true,
          component: 'v-checkbox',
          falseValue: false,
          name: 'isBillingAddress',
          label: this.$t('cmac.billingAddress'),
          trueValue: true,
        },
        {
          boolean: true,
          component: 'v-checkbox',
          name: 'isDepotAddress',
          label: this.$t('cmac.depotAddress'),
        },
      ],
      emailAddress: [
        {
          name: 'description',
          label: this.$t('common.description'),
          rules: [isAlphaNumericWithSpecials, isRequired],
        },
        {
          name: 'value',
          label: this.$t('fields.emailAddress'),
          rules: [isAlphaNumericWithSpecials, isRequired, isEmailAddress],
        },
      ],
      telephoneNumber: [
        {
          name: 'description',
          label: this.$t('common.description'),
          rules: [isAlphaNumericWithSpecials, isRequired],
        },
        {
          name: 'value',
          label: this.$t('fields.telephoneNumber'),
          rules: [isAlphaNumericWithSpecials, isRequired, isTelephoneNumber, minChar(10)],
        },
      ],
    }
    switch (type) {
      case ADDRESS:
        return fields.address
      case EMAIL_ADDRESS:
        return fields.emailAddress
      case TELEPHONE_NUMBER:
        return fields.telephoneNumber
      default:
        return []
    }
  }
  get items() {
    return this.contactItems.filter((item: Record<string, any>) => {
      return !!this.contactItemTypes.find((type: Record<string, any>) => {
        return type.id === item.contactItemType
      })
    })
  }
  get loading(): boolean {
    const {
      fetchingAddresses,
      fetchingAddressTypes,
      fetchingContactItems,
      fetchingContactItemTypes,
    } = this
    return (
      fetchingAddresses || fetchingAddressTypes || fetchingContactItems || fetchingContactItemTypes
    )
  }
  get modalLoading(): boolean {
    const { updatingAddress, updatingContactItem, storingAddress, storingContactItem } = this
    return updatingAddress || updatingContactItem || storingAddress || storingContactItem
  }
  get modalTitle(): string {
    const name = this.getModalTypeName(this.modal.type)
    switch (this.modal.mode) {
      case ADD:
        return this.$t(`cmac.add${name}`) as string
      case EDIT:
        return this.$t(`cmac.edit${name}`) as string
      default:
        return this.$t(`common.${name}`) as string
    }
  }
  get storingAddress(): boolean {
    const { storeAddress: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get storingContactItem(): boolean {
    const { storeContactItem: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get telephoneNumbers(): Record<string, any>[] {
    return this.getContactItemsByType('Phone')
  }
  get updatingAddress(): boolean {
    const { updateAddress: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'PUT', url })
  }
  get updatingContactItem(): boolean {
    const { updateContactItem: url } = this.urls
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'POST', url })
  }
  get urls(): Record<string, any> {
    return {
      getAddresses: 'organisation/getAddresses/' + this.supplier?.id,
      getAddressTypes: 'organisation/getAddressTypes',
      getContactItems: 'organisation/getContactItems/' + this.supplier?.id,
      getContactItemTypes: 'organisation/getContactItemTypes',
      deleteContactItem: 'organisation/deletecontactitem/' + this.supplier?.id,
      deleteAddress: 'organisation/removeAddress',
      storeContactItem: 'organisation/addeditcontactitem/' + this.supplier?.id,
      storeAddress: 'organisation/addAddress',
      updateContactItem: 'organisation/addeditcontactitem/' + this.supplier?.id,
      updateAddress: 'organisation/editAddress',
    }
  }

  // watch // ***
  @Watch('modal.open')
  onValueChanged(e: boolean) {
    if (!e) this.resetModal()
  }

  @Watch('supplier')
  onSupplierChanged(): void {
    this.fetchAll()
  }

  // created // ***
  created() {
    this.onCreated()
  }

  // methods // ***
  addNotificationEmailAddress() {
    this.emailAddressToEdit = {
      id: 0,
      contactType: this.emailContactItemTypes.find((x: any) => x.type === 9),
      otherDescription: '',
      email: '',
    }
    this.showEmailAddressModal = true
  }
  addEmailAddress() {
    this.emailAddressToEdit = {
      id: 0,
      contactType: this.emailContactItemTypes.find((x: any) => x.type === 2),
      otherDescription: '',
      email: '',
    }
    this.showEmailAddressModal = true
  }
  editEmailAddress(emailAddress: any) {
    let type = this.computedEmailContactItemTypes.find(
      (x: any) => x.value === emailAddress.description
    )
    let otherType = this.computedEmailContactItemTypes.find(
      (x: any) => x.value === this.$t('contactItem.other')
    )
    console.log('type', type)
    console.log('otherType', otherType)
    this.emailAddressToEdit = {
      id: emailAddress.id,
      contactType: type ? type : otherType,
      otherDescription: type && type != otherType ? '' : emailAddress.description,
      email: emailAddress.value,
    }
    console.log('this.emailAddressToEdit', this.emailAddressToEdit)
    this.showEmailAddressModal = true
  }
  saveEmailAddress() {
    if (!this.validateEmailForm()) return
    this.savingEmailAddress = true
    const data = {
      id: this.emailAddressToEdit.id,
      contactItemType: this.emailAddressToEdit.contactType.type,
      description: this.emailAddressToEdit.otherDescription
        ? this.emailAddressToEdit.otherDescription
        : this.emailAddressToEdit.contactType.value,
      fullDescription: `${this.emailAddressToEdit.contactType.value} ${this.emailAddressToEdit.email}`,
      value: this.emailAddressToEdit.email,
    }
    api(
      'organisation/addeditcontactitem/' + this.supplier?.id,
      { data, json: true, method: 'POST' },
      undefined
    )
      .then(() => {
        this.fetchContactItems()
      })
      .finally(() => {
        this.showEmailAddressModal = false
        this.savingEmailAddress = false
      })
  }
  validateEmailForm() {
    const refs: Record<string, any> = this.$refs
    return refs.emailForm.validate()
  }
  resetEmailForm() {
    const refs: Record<string, any> = this.$refs
    return refs.emailForm.resetValidation()
  }
  deleteAddress(item: Record<string, any>) {
    const { deleteAddress: url } = this.urls
    const data = { organisationId: this.supplier?.id, addressId: item.id }
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.resetDeleteModal()
      this.fetchAddresses()
    })
  }
  deleteEmailAddress(item: Record<string, any>) {
    const { deleteContactItem: url } = this.urls
    const data = { contactItemId: item.id }
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.resetDeleteModal()
      this.fetchContactItems()
    })
  }
  deleteTelephoneNumber(item: Record<string, any>) {
    const { deleteContactItem: url } = this.urls
    const data = { contactItemId: item.id }
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.resetDeleteModal()
      this.fetchContactItems()
    })
  }
  fetchAll() {
    if (!this.supplier?.id) return
    this.fetchAddresses()
    this.fetchContactItems()
  }
  async fetchAddresses() {
    if (!this.supplier?.id) return
    const { getAddresses, getAddressTypes } = this.urls
    // fetch address types
    const types = (await api(getAddressTypes, undefined, undefined)) || {}
    if (!types?.data) return
    this.addressTypes = types?.data
    // fetch contact items
    const items = (await api(getAddresses, undefined, undefined)) || {}
    if (!items?.data) return
    this.addresses = items?.data
  }
  async fetchContactItems() {
    if (!this.supplier?.id) return
    const { getContactItemTypes, getContactItems } = this.urls
    // fetch contact item types
    const types = (await api(getContactItemTypes, undefined, undefined)) || {}
    if (!types?.data) return
    this.contactItemTypes = types?.data
    // fetch contact items
    const items = (await api(getContactItems, undefined, undefined)) || {}
    if (!items?.data) return
    this.contactItems = items?.data
  }
  getAddressPayload(values: Record<string, any>) {
    const types = ['ContactAddress']
    if (values.isBillingAddress) types.push('BillingAddress')
    if (values.isDepotAddress) types.push('CoverageArea')
    const payload = { ...values }
    delete payload.isBillingAddress
    delete payload.isDepotAddress
    return {
      id: this.supplier?.id,
      organisationId: this.supplier?.id,
      address: { ...payload, addressType: types },
    }
  }
  getContactItemPayload(
    type: string | number,
    values: Record<string, any>,
    item: Record<string, any> | undefined = {}
  ) {
    const payload = { ...values }
    if (!item?.id) payload.id = 0
    else payload.id = item.id
    if (type === EMAIL_ADDRESS) {
      payload.contactItemType = 2
    } else if (type === TELEPHONE_NUMBER) payload.contactItemType = 1
    return payload
  }
  getContactItemsByType(type: string): Record<string, any>[] {
    const types = this.contactItemTypes
      .filter((t: Record<string, any>) => t.type === type)
      .map((t: Record<string, any>) => t.id)
    return this.contactItems.filter((i: Record<string, any>) => {
      return types.includes(i.contactItemType)
    })
  }
  getModalTypeName(type: string | number): string {
    switch (type) {
      case ADDRESS:
        return 'Address'
      case EMAIL_ADDRESS:
        return 'EmailAddress'
      case TELEPHONE_NUMBER:
        return 'TelephoneNumber'
      default:
        return 'unknown'
    }
  }
  onAdd(type: string) {
    this.resetModal()
    this.modal.mode = ADD
    this.modal.type = type
    this.modal.open = true
  }
  onAutocomplete(e: Record<string, any>): void {
    const { item, values } = e
    const { address1, address2, country, isoCountryCode, lat, long, postCode, region, town } =
      values
    const newItem = {
      ...cloneDeep(item),
      address1,
      address2,
      country,
      isoCountryCode,
      lat,
      long,
      postCode,
      region,
      town,
    }
    Vue.set(this.modal, 'item', newItem)
  }
  onContactItemDeleteModalSubmit() {
    const { deleteModal } = this
    const { item, type } = deleteModal
    if (type === ADDRESS) this.deleteAddress(item)
    else if (type === EMAIL_ADDRESS) this.deleteEmailAddress(item)
    else if (type === TELEPHONE_NUMBER) this.deleteTelephoneNumber(item)
  }
  onContactItemModalSubmit(values: Record<string, any>) {
    const { modal } = this
    const { item, mode, type } = modal
    if (mode === ADD) {
      if (type === ADDRESS) this.storeAddress(values)
      else if (type === EMAIL_ADDRESS) this.storeEmailAddress(type, values)
      else if (type === TELEPHONE_NUMBER) this.storeTelephoneNumber(type, values)
    } else if (mode === EDIT) {
      if (type === ADDRESS) this.updateAddress(item, values)
      else if (type === EMAIL_ADDRESS) this.updateEmailAddress(item, type, values)
      else if (type === TELEPHONE_NUMBER) this.updateTelephoneNumber(item, type, values)
    }
  }
  async onCreated() {
    this.fetchAll()
  }
  onEditAddress(e: Record<string, any>) {
    this.openEditModal(ADDRESS, e)
  }
  onEditEmailAddress(e: Record<string, any>) {
    this.openEditModal(EMAIL_ADDRESS, e)
  }
  onEditTelephoneNumber(e: Record<string, any>) {
    this.openEditModal(TELEPHONE_NUMBER, e)
  }
  onDeleteAddress(e: Record<string, any>) {
    this.openDeleteModal(ADDRESS, e)
  }
  onDeleteEmailAddress(e: Record<string, any>) {
    this.openDeleteModal(EMAIL_ADDRESS, e)
  }
  onDeleteTelephoneNumber(e: Record<string, any>) {
    this.openDeleteModal(TELEPHONE_NUMBER, e)
  }
  onFieldInput(e: Record<string, any>): void {
    this.updateModalfield(e)
  }
  openDeleteModal(type: string | number, e: Record<string, any>) {
    this.resetDeleteModal()
    this.deleteModal.item = e
    this.deleteModal.type = type
    this.deleteModal.open = true
  }
  openEditModal(type: string | number, e: Record<string, any>) {
    this.resetModal()
    this.modal.mode = EDIT
    this.modal.item = e
    this.modal.type = type
    this.modal.open = true
  }
  resetDeleteModal() {
    Vue.set(this, 'deleteModal', cloneDeep(DEFAULT_DELETE_MODAL_DATA))
  }
  resetModal() {
    Vue.set(this, 'modal', cloneDeep(DEFAULT_MODAL_DATA))
  }
  storeAddress(values: Record<string, any>) {
    const { storeAddress: url } = this.urls
    const data = this.getAddressPayload(values)
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.resetModal()
      this.fetchAddresses()
    })
  }
  storeContactItem(type: string | number, values: Record<string, any>) {
    const { storeContactItem: url } = this.urls
    const data = this.getContactItemPayload(type, values)
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.resetModal()
      this.fetchContactItems()
    })
  }
  storeEmailAddress(type: string | number, values: Record<string, any>) {
    this.storeContactItem(type, values)
  }
  storeTelephoneNumber(type: string | number, values: Record<string, any>) {
    this.storeContactItem(type, values)
  }
  updateAddress(item: Record<string, any>, values: Record<string, any>) {
    const { updateAddress: url } = this.urls
    const data = { ...this.getAddressPayload(values), addressId: item?.id }
    api(url, { data, json: true, method: 'PUT' }, undefined).then(() => {
      this.resetModal()
      this.fetchAddresses()
    })
  }
  updateContactItem(item: Record<string, any>, type: string | number, values: Record<string, any>) {
    const { updateContactItem: url } = this.urls
    const data = this.getContactItemPayload(type, values, item)
    api(url, { data, json: true, method: 'POST' }, undefined).then(() => {
      this.resetModal()
      this.fetchContactItems()
    })
  }
  updateEmailAddress(
    item: Record<string, any>,
    type: string | number,
    values: Record<string, any>
  ) {
    this.updateContactItem(item, type, values)
  }
  updateModalfield(context: Record<string, any>): void {
    const { field, value } = context
    Vue.set(this.modal.item, field.name, value)
  }
  updateTelephoneNumber(
    item: Record<string, any>,
    type: string | number,
    values: Record<string, any>
  ) {
    this.updateContactItem(item, type, values)
  }
}
