
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ContactItemEmailAddress extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  item!: Record<string, any>
  @Prop({ type: Boolean, default: false })
  isPermitHolder!: boolean

  // methods // ***
  onDelete() {
    this.$emit('delete', this.item)
  }
  onEdit() {
    this.$emit('edit', this.item)
  }
}
